import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'

const View = dynamic(() => import('./UpdatedTermsDialog'))

export const UpdatedTermsDialog = () => {
  const router = useRouter()
  const userStore = useUser()

  return userStore.isMember &&
    !router.pathname.startsWith('/onboarding') &&
    !router.pathname.startsWith('/terms-of-service') &&
    !router.pathname.startsWith('/privacy-policy') ? (
    <View />
  ) : null
}
