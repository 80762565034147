import { breakpoint } from 'src/styles'
import { actions, useToastContext } from 'src/components/Toast'

export const Action = ({ id, content = 'Perform Action', onClick }) => {
  const { toastDispatch } = useToastContext()

  React.useEffect(() => {
    const timeout = setTimeout(() => deleteSelf(), 3000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteSelf = () =>
    toastDispatch({
      type: actions.REMOVE,
      payload: {
        id,
      },
    })

  const onClickHandler = () => {
    if (typeof onClick === 'function') onClick()
    deleteSelf()
  }

  return <Styles.Container onClick={onClickHandler}>{content}</Styles.Container>
}

const Styles = {
  Container: styled.button`
    background: rgba(255, 255, 255, 0.9);
    border-radius: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 345px;

    @media screen and (max-width: 374px) {
      width: 100%;
    }

    ${breakpoint.mediumAndUp} {
      height: 60px;
    }
  `,
}
