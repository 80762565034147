// import { useRouter } from 'src/hooks/useRouter'
// import { useUser } from 'src/hooks/useUser'

const View = dynamic(() => import('./FeatureAnnouncementDialog'))

// const disabledRoutes = [
//   '/onboarding/subscription',
//   '/videos',
//   '/videos/replay-tour',
// ]

export const FeatureAnnouncementDialog = () => {
  // const { asPath } = useRouter()
  // const { currentUser } = useUser()
  // const featureAnnouncements = currentUser?.account?.conditionalDisplayAttributes?.featureAnnouncements

  const [showDialog] = React.useState(false)

  return showDialog ? <View /> : null
}
