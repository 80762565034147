import { button, breakpoint, mixin } from 'src/styles'
import { Dialog } from 'src/components/Dialog'
import { Dismiss } from './Display/Dismiss'
import { getInviteEmailUrl } from 'src/utils/getInviteEmailUrl'
import { getObeContentBySlug } from 'src/models/content'
import { getVodInviteEmailUrl } from 'src/utils/getVodInviteEmailUrl'
import { Image } from 'src/components/Display/Image'
import { referralUrl } from 'src/utils/referralUrl'
import { RichText } from 'src/components/Display/RichText'
import { useAsyncCallback } from 'react-async-hook'
import { useCopiedValue } from 'src/hooks/useCopiedValue'
import { UserAction } from './UserAction'
import { useUiState } from 'src/hooks/useStores'
import { useUser } from 'src/hooks/useUser'
import { vodReferralUrl } from 'src/utils/vodReferralUrl'

export const GuestPassDialog = ({ isOpen, onClose, video, source: _source }) => {
  const { currentUser, isStripeUser, isNonbillable } = useUser()
  const { uiStore } = useUiState()

  const firstName = currentUser?.profile?.firstName
  const id = currentUser?.id
  const source = _source || uiStore.guestPassModal.source

  const shareLink = video ? vodReferralUrl(id, firstName, video) : referralUrl(id, firstName)
  const { setHasCopied, hasCopied, copyValue } = useCopiedValue(shareLink)

  const {
    result,
    execute: getModal,
    status,
  } = useAsyncCallback(
    async () =>
      await getObeContentBySlug(`obe-for-everyone${isStripeUser || isNonbillable ? '' : '-app'}`),
  )

  React.useEffect(() => {
    if (isOpen && status !== 'success') getModal()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const sendEmail = React.useCallback(() => {
    if (video) {
      window.open(getVodInviteEmailUrl(shareLink, video), '_blank')
    } else {
      window.open(getInviteEmailUrl(shareLink), '_blank')
    }
  }, [video, shareLink])

  const clickEventParams = {
    context: source,
    launchedAfterClassNumber: source?.startsWith('auto-')
      ? uiStore.guestPassModal?.launchedAfterClassNumber
      : undefined,
  }

  return (
    <React.Fragment>
      <Styles.Dialog
        containerStyle={{
          ...(navigator.platform === 'iPhone' ? { paddingBottom: 84 } : {}),
        }}
        isOpen={isOpen}
        onClose={onClose}
        onClosed={() => setHasCopied(false)}
      >
        <Styles.Dismiss onClick={onClose}>
          <Dismiss />
        </Styles.Dismiss>
        {result?.imageUrl ? <Styles.Image src={result?.imageUrl} height='auto' /> : null}
        <Styles.Header>{result?.title}</Styles.Header>
        <Styles.Description center centraBook dangerouslySetInnerHTML={{ __html: result?.body }} />
        <Styles.CopyButton
          action={obe.events.copied_guest_pass_link}
          tracking={clickEventParams}
          onClick={copyValue}
        >
          {hasCopied ? 'Copied!' : 'Copy Invitation Link'}
        </Styles.CopyButton>
        <EmailInviteButton source={source} sendEmail={sendEmail} />
      </Styles.Dialog>
    </React.Fragment>
  )
}

export const EmailInviteButton = ({ source, sendEmail }) => (
  <Styles.EmailButton
    action={obe.events.clicked_guest_pass_email_invite}
    tracking={{ context: source }}
    onClick={sendEmail}
  >
    <u>Send an email invite</u>
  </Styles.EmailButton>
)

GuestPassDialog.propTypes = {
  currentUser: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  video: PropTypes.object,
}

const Styles = {
  Dialog: styled(Dialog.Main)`
    position: relative;
    overflow-x: hidden;
    max-width: 500px;
    padding: 0 0 2rem;

    ${breakpoint.mediumAndDown} {
      max-height: 90vh;
      overflow: auto;
    }
  `,
  Dismiss: styled.button`
    position: absolute;
    z-index: 1;
    top: 9px;
    right: 15px;
    padding: 0;
    background: none;
    color: inherit;
    border: none;
    outline: inherit;
  `,
  Image: styled(Image)`
    > div > img {
      margin: 0 -12px;
      width: calc(100% + 24px);
      max-width: calc(100% + 24px);
    }
  `,
  Description: styled(RichText.Large)`
    padding: 0 3rem;
    ul {
      text-align: left;
    }

    ${breakpoint.smallAndDown} {
      padding: 0 2rem;
    }
  `,
  Header: styled.h1`
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    margin: 26px 0 12px;

    ${breakpoint.smallAndDown} {
      margin: 16px 0;
    }
  `,
  CopyButton: styled(UserAction)`
    ${button.primary}
    padding: .5rem 1rem;
    width: calc(100% - 6rem);
    margin: 1rem auto 0;

    ${breakpoint.smallAndDown} {
      width: calc(100% - 4rem);
    }
  `,
  InviteYourContacts: styled.div`
    ${mixin.flexCenterBoth}
    flex-direction: column;
    margin-top: 1.5rem;
    font-size: 18px;
    font-weight: 300;

    ${breakpoint.smallAndDown} {
      flex-direction: column;
    }
  `,
  EmailButton: styled(UserAction)`
    text-align: center;
    margin-top: 1rem;
  `,
  ShareBox: styled.div`
    display: flex;
    ${breakpoint.mediumAndDown} {
      ${mixin.spaceChildrenHorizontal('10px')}
    }

    ${breakpoint.largeAndUp} {
      flex-direction: row;
      padding: 5px 50px;
    }
  `,
}
