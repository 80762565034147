import { breakpoint, button } from 'src/styles'
import { CollectionItem } from './CollectionItem'
import { Header } from './Header'
import { isAllCollection } from 'src/utils/isAllCollection'
import { useBookmarkCollections } from 'src/hooks/useBookmarkCollections'

export const CollectionView = ({
  activeCollection,
  assets,
  disabled,
  isBulkMove,
  onClose,
  onBulkMove,
  onCollectionSave,
  onNextSlide,
}) => {
  const { bookmarkCollections } = useBookmarkCollections()
  const isIphone = navigator.platform === 'iPhone'

  const headerCopy = (() => {
    if (isBulkMove && isAllCollection(activeCollection?.name)) return 'Add to collection'

    if (isBulkMove) return 'Move to collection'

    return 'Save to collection'
  })()

  return (
    <Styles.Container>
      <Header title={headerCopy} onClose={onClose} />
      <Styles.CollectionContainer isIphone={isIphone}>
        {bookmarkCollections?.map((collection) => (
          <CollectionItem
            key={collection.id}
            assets={assets}
            collection={collection}
            disabled={disabled || collection.id === activeCollection?.id}
            onCollectionSave={isBulkMove ? onBulkMove : onCollectionSave}
          />
        ))}
      </Styles.CollectionContainer>
      <Styles.Footer isIphone={isIphone}>
        <Styles.CreateButton disabled={disabled} onClick={onNextSlide}>
          + CREATE NEW COLLECTION
        </Styles.CreateButton>
      </Styles.Footer>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    position: relative;
  `,
  CollectionContainer: styled.div`
    padding-bottom: 100px;

    ${({ isIphone }) =>
      isIphone &&
      css`
        padding-bottom: 200px;
      `}
  `,
  Footer: styled.div`
    position: fixed;
    bottom: 0;
    height: 95px;
    width: 100%;
    max-width: 693px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    z-index: 100;
    background: #f8f8f8;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);

    ${breakpoint.mediumAndUp} {
      height: 80px;
      max-width: 500px;
      box-shadow: none;
    }

    ${({ isIphone }) =>
      isIphone &&
      css`
        height: 190px;
        justify-content: center;
        align-items: flex-start;
        padding-top: 15px;
      `}
  `,
  CreateButton: styled.button`
    ${button.secondary}
    max-width: 336px;
    width: 100%;
  `,
}
