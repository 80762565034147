import { generateBookmarksUserKey } from './index'
import { mutate } from 'swr'

export const setBookmarkCollections = (newBookmarkCollections, userId) => {
  if (newBookmarkCollections) {
    mutate(generateBookmarksUserKey(userId), newBookmarkCollections, false)
  }
}

export const clearCache = () => {
  mutate(generateBookmarksUserKey(), null, false)
}
