import { actions, useToastContext } from 'src/components/Toast'
import { breakpoint } from 'src/styles'
import { Link as _Link } from 'src/components/Link'

export const Link = ({ id, content = 'Link', linkLabel = 'View', to }) => {
  const { toastDispatch } = useToastContext()

  React.useEffect(() => {
    const deleteSelf = () =>
      toastDispatch({
        type: actions.REMOVE,
        payload: {
          id,
        },
      })

    setTimeout(() => deleteSelf(), 2000)
  }, [id, toastDispatch])

  return (
    <Styles.Container to={to}>
      {content}
      <Styles.CTA>{linkLabel}</Styles.CTA>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled(_Link)`
    background: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
    opacity: 0.9;
    backdrop-filter: blur(20px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: 345px;

    @media screen and (max-width: 374px) {
      width: 100%;
    }

    ${breakpoint.mediumAndUp} {
      height: 60px;
    }

    :hover {
      text-decoration: none;
    }
  `,
  CTA: styled.div`
    border-bottom: 1px solid var(--nearBlack);
  `,
}
