import * as Sentry from '@sentry/nextjs'

import { api, client } from 'src/api'
import type { BookmarkCollectionType } from 'src/types/BookmarkCollections'
import type { UserType } from 'src/types/User'

export const bulkUnsaveObeClasses = async (classIds: string[]) =>
  client.post<
    {
      bookmarkCollections: BookmarkCollectionType
    } & Pick<
      UserType,
      | 'id'
      | 'email'
      | 'favoriteVideos'
      | 'freemiumClassesRemaining'
      | 'freemiumEligibility'
      | 'isMuscleGuard'
      | 'profile'
      | 'savedVideos'
    >
  >('/api/v3/saved_obe_classes/bulk_unsave', {
    obeClassIds: classIds,
  })

export const getSavedObeClasses = async (userId?: string) => {
  if (!api.sessionState) return null
  try {
    const { data } = await client.get<
      Pick<
        UserType,
        | 'email'
        | 'favoriteVideos'
        | 'freemiumClassesRemaining'
        | 'freemiumEligibility'
        | 'id'
        | 'isMuscleGuard'
        | 'profile'
        | 'role'
        | 'savedVideos'
      >
    >('/api/v3/saved_obe_classes', {
      params: {
        ...(userId ? { for_member: userId } : {}),
      },
    })
    return data
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

export const saveObeClass = async (obeClassId: string, context: string) =>
  await client.post<
    Pick<
      UserType,
      | 'id'
      | 'email'
      | 'favoriteVideos'
      | 'freemiumClassesRemaining'
      | 'freemiumEligibility'
      | 'isMuscleGuard'
      | 'profile'
      | 'savedVideos'
    >
  >('/api/v3/saved_obe_classes/save', {
    obeClassId,
    context,
  })

export const unsaveObeClass = async (obeClassId: string) =>
  await client.post<
    {
      bookmarkCollections: BookmarkCollectionType
    } & Pick<
      UserType,
      | 'id'
      | 'email'
      | 'favoriteVideos'
      | 'freemiumClassesRemaining'
      | 'freemiumEligibility'
      | 'isMuscleGuard'
      | 'profile'
      | 'savedVideos'
    >
  >('/api/v3/saved_obe_classes/unsave', {
    obeClassId,
  })
