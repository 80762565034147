import { Action } from './Action'
import { Confirmation } from './Confirmation'
import { Link } from './Link'

export const ToastRouter = ({ type, ...toast }) => {
  switch (type) {
    case 'action':
      return <Action {...toast} />
    case 'confirmation':
      return <Confirmation {...toast} />
    case 'link':
      return <Link {...toast} />
    default:
      return null
  }
}
