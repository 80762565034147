export const getVodInviteEmailUrl = (shareUrl, video) => {
  const subject = 'Check out this obé Fitness class!'

  const body = encodeURIComponent(
    [
      `Just finished this amazing obé ${
        video.classType?.name || 'fitness'
      } class and I think you'll love it!`,
      "To get started, click the link below for a special, 1-month guest pass. Can't wait to hear what you think!",
      'P.S. The 1-month guest pass can only be obtained through the link below.',
      shareUrl,
    ].join('\n\n'),
  )

  return `mailto:?subject=${subject}&body=${body}`
}
