import { breakpoint } from 'src/styles'
import { Arrow } from 'src/components/Display/Arrow'
import { Dismiss } from 'src/components/Display/Dismiss'

export const Header = ({ onClose, onPrevSlide, title }) => {
  return (
    <Styles.Container>
      <Styles.Title>{title}</Styles.Title>
      {typeof onPrevSlide === 'function' && (
        <Styles.BackButton onClick={onPrevSlide}>
          <Styles.Arrow />
        </Styles.BackButton>
      )}
      <Styles.Dismiss onClick={onClose}>
        <Dismiss />
      </Styles.Dismiss>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    position: sticky;
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 102;
    top: 0;
    background: #f8f8f8;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);

    ${breakpoint.mediumAndUp} {
      height: 80px;
      padding-bottom: 10px;
      box-shadow: none;
    }
  `,
  Title: styled.h2`
    font-size: 20px;
    font-weight: 250;
    text-align: center;
    padding-top: 0;
    margin: 0;
    line-height: 34px;
    letter-spacing: 0em;

    ${breakpoint.mediumAndUp} {
      font-size: 32px;
      padding-top: 15px;
    }
  `,
  BackButton: styled.button`
    position: absolute;
    left: 16px;
    top: 20px;
    background: none;
    border: none;
    outline: inherit;

    ${breakpoint.smallAndDown} {
      top: 25px;
    }
  `,
  Arrow: styled(Arrow.Left)`
    width: 18px;
    height: 16px;
  `,
  Dismiss: styled.button`
    position: absolute;
    padding: 0;
    top: 14px;
    right: 20px;
    background: none;
    color: inherit;
    border: none;
    outline: inherit;

    ${breakpoint.smallAndDown} {
      top: 20px;
    }
  `,
}
