import * as actions from './actions'

export const initialState = []

export const toastReducer = (state, action) => {
  const {
    type: actionType,
    payload: { content, id, onClick, to, type },
  } = action

  switch (actionType) {
    case actions.ADD:
      return [
        ...state,
        {
          content,
          id,
          onClick,
          to,
          type,
        },
      ]
    case actions.REMOVE:
      return state.filter((toast) => (Array.isArray(id) ? !id.includes(toast.id) : toast.id !== id))
    case actions.REMOVE_ALL:
      return initialState
    default:
      return state
  }
}
