import { getContentCollectionBySlug } from './collection'
import { client } from 'src/api'

export const getAssetUrlCollectionBySlug = async (slug: string) => {
  const data = await getContentCollectionBySlug(slug)

  const assets: Record<string, string> = {}
  for (const file of data.directusFiles) {
    assets[file.slug] = file.url
  }

  return assets
}

export const getAssetBySlug = async (slug: string) => {
  const { data } = await client.get<{
    id: string
    url: string
    title: string
    type: string
    description: string
    slug: string
  }>('/api/v4/obe_assets/by_slug', { params: { slug } })
  return data
}
