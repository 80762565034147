import { ToastHub } from './ToastHub'
import { initialState, toastReducer } from './reducer'

const Portal = dynamic(() => import('src/components/Portal'), { ssr: false })

const ToastContext = React.createContext()

export const ToastProvider = ({ children }) => {
  const [toasts, toastDispatch] = React.useReducer(toastReducer, initialState)

  return (
    <ToastContext.Provider value={{ toasts, toastDispatch }}>
      {children}
      <Portal className="root-toast-portal">
        <ToastHub />
      </Portal>
    </ToastContext.Provider>
  )
}

export const useToastContext = () => {
  return React.useContext(ToastContext)
}
