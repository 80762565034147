export const useCopiedValue = (value) => {
  const [hasCopied, setHasCopied] = React.useState(false)

  const copyValue = React.useCallback(() => {
    const hiddenEl = document.createElement('textarea')

    hiddenEl.style.position = 'fixed'
    hiddenEl.style.opacity = '0'
    hiddenEl.textContent = value

    document.body.appendChild(hiddenEl)
    hiddenEl.select()
    hiddenEl.setSelectionRange(0, 999)
    document.execCommand('copy')
    document.body.removeChild(hiddenEl)

    setHasCopied(true)
  }, [value])

  return { setHasCopied, hasCopied, copyValue }
}
