import { client } from 'src/api'
import { ObeContentType } from 'src/types/ObeContent'

export const getObeContentBySlug = async (slug: string) => {
  const { data } = await client.get<ObeContentType>('/api/v4/content/by_slug', { params: { slug } })
  return data
}

export const getObeContentByType = async (type: string) => {
  const { data } = await client.get<ObeContentType[]>('/api/v4/content/by_type', {
    params: { type },
  })
  return data
}
