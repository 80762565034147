import type { SVGProps } from 'react'

export const Chevron = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='13'
    height='8'
    viewBox='0 0 13 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.5 6.5L6.14645 6.85355L6.5 7.20711L6.85355 6.85355L6.5 6.5ZM12.3536 1.35355C12.5488 1.15829 12.5488 0.841709 12.3536 0.646447C12.1583 0.451184 11.8417 0.451184 11.6464 0.646447L12.3536 1.35355ZM0.646447 1.35355L6.14645 6.85355L6.85355 6.14645L1.35355 0.646447L0.646447 1.35355ZM6.85355 6.85355L12.3536 1.35355L11.6464 0.646447L6.14645 6.14645L6.85355 6.85355Z'
      fill='black'
    />
  </svg>
)
