'use client'

import type { ReactNode } from 'react'

import { BreakageBouncer } from 'src/components/App/BreakageBouncer'
import { ExceptionDispatcher } from 'src/components/App/ExceptionDispatcher'
import { Footer } from 'src/components/App/Footer'
import { Header } from 'src/components/App/Header'
import { OnboardingBouncer } from 'src/components/App/OnboardingBouncer'
import { BookmarkVideoDialog } from 'src/components/BookmarkVideoDialog'
import { FeatureAnnouncementDialog } from 'src/components/FeatureAnnouncementDialog'
import { GuestPassDialog } from 'src/components/GuestPassDialog'
import { ToastProvider } from 'src/components/Toast'
import { UpdatedTermsDialog } from 'src/components/UpdatedTermsDialog'
import { SignUpContextProvider } from 'src/hooks/useSignUpContext/SignUpContextProvider'
import { useUiState } from 'src/hooks/useStores'
import { useUser } from 'src/hooks/useUser'
import { CouponProvider } from 'src/providers/CouponProvider/CouponProvider'
import { twMerge } from 'tailwind-merge'

interface Props {
  isEmbedded?: boolean
  children?: ReactNode
  hideHeader?: boolean
  hideFooter?: boolean
  className?: string
}

export const MainLayout = ({ isEmbedded, hideHeader, hideFooter, className, children }: Props) => {
  const { uiStore, setGuestPassModal } = useUiState()
  const { isInactive, isMember, isLoading } = useUser()

  if (isLoading) return null

  return (
    <ExceptionDispatcher>
      <BreakageBouncer isInactive={isInactive}>
        <SignUpContextProvider>
          <CouponProvider>
            <OnboardingBouncer>
              <ToastProvider>
                <div className={twMerge(className, !className && 'gradient-light')}>
                  <div className="min-h-screen">
                    {!isEmbedded && !hideHeader && <Header logo={undefined} />}
                    {hideHeader && <main>{children}</main>}
                    {!hideHeader && (
                      <main
                        className="pt-[calc(var(--mobile-header-height)+var(--coupon-banner-height))]
                          lg:pt-[calc(var(--landing-header-height)+var(--coupon-banner-height))]"
                      >
                        {children}
                      </main>
                    )}
                  </div>
                  {!isEmbedded && !hideFooter && <Footer />}
                </div>
                <BookmarkVideoDialog />
                {isMember && <FeatureAnnouncementDialog />}
                <UpdatedTermsDialog />
                <GuestPassDialog
                  source={undefined}
                  isOpen={uiStore?.guestPassModal?.isOpen}
                  onClose={() => setGuestPassModal({ isOpen: false })}
                />
              </ToastProvider>
            </OnboardingBouncer>
          </CouponProvider>
        </SignUpContextProvider>
      </BreakageBouncer>
    </ExceptionDispatcher>
  )
}
