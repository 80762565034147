import { getSavedObeClassesUserKey } from './index'
import { mutate } from 'swr'

export const setSavedClasses = ({
  favoriteVideos,
  savedVideos,
}: {
  favoriteVideos: string[]
  savedVideos: string[]
}) => {
  mutate(
    getSavedObeClassesUserKey(),
    (data) => ({
      ...data,
      favoriteVideos,
      savedVideos,
    }),
    false,
  )
}

export const clearCache = () => {
  mutate(getSavedObeClassesUserKey(), null, false)
}
