import { breakpoint } from 'src/styles'
import { actions, useToastContext } from 'src/components/Toast'

export const Confirmation = ({ id, content = 'Action Confirmation' }) => {
  const { toastDispatch } = useToastContext()

  const deleteSelf = () =>
    toastDispatch({
      type: actions.REMOVE,
      payload: {
        id,
      },
    })

  React.useEffect(() => {
    const timeout = setTimeout(() => deleteSelf(), 2000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Styles.Container>{content}</Styles.Container>
}

const Styles = {
  Container: styled.div`
    background: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
    opacity: 0.9;
    backdrop-filter: blur(20px);
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 345px;

    @media screen and (max-width: 374px) {
      width: 100%;
    }

    ${breakpoint.mediumAndUp} {
      height: 60px;
    }
  `,
}
