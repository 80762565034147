import { breakpoint } from 'src/styles'
import { animated, useTransition } from 'react-spring'
import { ToastRouter } from './ToastTypes'
import { useToastContext } from './ToastContext'

export const ToastHub = () => {
  const { toasts } = useToastContext()
  const [refMap] = React.useState(() => new Map())

  const transitions = useTransition(toasts, (item) => item.id, {
    enter: (item) => async (next) => {
      await next({
        opacity: 1,
        height: refMap.get(item.id).offsetHeight,
      })
    },
    leave: (item) => async (next) => {
      refMap.delete(item.id)
      await next({ opacity: 0, height: 0 })
    },
    from: { opacity: 0, height: 0, width: '100%' },
  })

  return (
    <Styles.Container>
      {transitions.map(({ item, props, key }) => (
        <Styles.Life key={key} style={props}>
          <div
            ref={(ref) => ref && refMap.set(item.id, ref)}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ToastRouter {...item} />
          </div>
        </Styles.Life>
      ))}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    position: fixed;
    z-index: 10000;
    bottom: 30px;
    width: 100vw;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${breakpoint.mediumAndUp} {
      right: 30px;
      width: 345px;
      padding: 0;
    }
  `,
  Life: styled(animated.div)`
    margin: 5px 0;
  `,
}
