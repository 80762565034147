import { breakpoint, button } from 'src/styles'
import { COLLECTION_TYPES } from 'src/models/collections'
import { isFavoritesCollection } from 'src/utils/isFavoritesCollection'
import { Icon } from 'src/components/Icon'
import { Image } from 'src/components/Display/Image'
import { StackedPaper } from 'src/components/StackedPaper'
import { truncateString } from 'src/utils/truncateString'
import { useResponsive } from 'src/hooks/useResponsive'

export const CollectionItem = ({ assets, collection = {}, disabled, onCollectionSave }) => {
  const { isSmallAndDown, isMediumAndUp } = useResponsive()

  const [isHover, setIsHover] = React.useState(false)

  const isFavorites = isFavoritesCollection(collection?.name)
  const thumbnailImage = isFavorites
    ? assets['favorites-collection-placeholder']
    : collection.thumbnailImage

  const onMouseEnter = () => {
    if (!disabled) {
      setIsHover(true)
    }
  }

  return (
    <Styles.Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setIsHover(false)}
      {...(isSmallAndDown && { onClick: () => onCollectionSave(collection) })}
    >
      <Styles.Content>
        <Styles.Title isFavorites={isFavorites}>
          <Styles.Paper>
            <Styles.ImageContainer>
              <Styles.Image
                src={thumbnailImage || assets?.['default-createnewcollection-placeholder']}
              />
            </Styles.ImageContainer>
          </Styles.Paper>
          {truncateString(collection.name, 15)}
        </Styles.Title>
        {isMediumAndUp && (
          <Styles.SaveButton
            disabled={disabled}
            isHover={isHover}
            onClick={() => onCollectionSave(collection)}
          >
            SAVE
          </Styles.SaveButton>
        )}
        {collection.privacySetting?.toUpperCase() === COLLECTION_TYPES.PRIVATE.ALL_CAPS && (
          <Styles.PrivacyIcon />
        )}
      </Styles.Content>
      {disabled && <Styles.DisabledOverlay />}
    </Styles.Container>
  )
}

const transitionTime = 300

const Styles = {
  Container: styled.div`
    padding: 0 40px;
    position: relative;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ${transitionTime}ms;
    background-color: #f8f8f8;
    :hover {
      background-color: #ebebeb;
    }
  `,
  Content: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: 420px;
  `,
  Title: styled.h3`
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
    font-weight: 400;
    font-size: 20px;
    word-wrap: break-word;

    ${({ isFavorites }) =>
      isFavorites &&
      css`
        text-transform: capitalize;
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        color: #979797;
      `}
  `,
  Paper: styled(StackedPaper)`
    width: 55px;
    height: 45px;
    margin-right: 20px;

    /* Second sheet of paper */
    ::before {
      left: 2px;
      top: 2px;
      z-index: -1;
    }

    /* Third sheet of paper */
    ::after {
      left: 5px;
      top: 5px;
      z-index: -2;
    }
  `,
  ImageContainer: styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
  `,
  Image: styled(Image)`
    height: 45px;
    width: 55px;

    > div {
      height: 45px;
      width: 55px;
    }

    > div > img {
      height: 45px;
      width: 55px;
      object-fit: cover;
    }
  `,
  SaveButton: styled.button`
    ${button.primary}
    opacity: 0;
    transition: opacity ${transitionTime}ms;
    z-index: 10;

    ${({ isHover }) =>
      isHover &&
      css`
        opacity: 1;
      `}
  `,
  PrivacyIcon: styled(Icon.Lock)`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    ${breakpoint.mediumAndUp} {
      right: 55px;
    }
  `,
  Saved: styled.p`
    margin: 0;
    color: #979797;
    font-weight: 400;
  `,
  DisabledOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    opacity: 0.7;
    z-index: 50;
  `,
}
