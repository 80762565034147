import { client, api } from 'src/api'
import { BookmarkCollectionType } from 'src/types/BookmarkCollections'
import { UserType } from 'src/types/User'

type BookmarkResponseType = Pick<
  UserType,
  | 'id'
  | 'email'
  | 'profile'
  | 'addressAttributes'
  | 'role'
  | 'freemiumEligibility'
  | 'freemiumClassesRemaining'
  | 'isMuscleGuard'
  | 'savedVideos'
  | 'favoriteVideos'
> & {
  bookmarkCollections: Omit<BookmarkCollectionType, 'obeClasses'>[]
  oauthProvider: string
}

export const addVideoToBookmarkCollection = (obeClassId: string, bookmarkCollectionId: string) =>
  client.post<BookmarkResponseType>('/api/v4/collection_videos', {
    bookmarkCollectionId,
    obeClassId,
  })

export const bulkCopyClassesToCollection = (obeClassIds: string[], bookmarkCollectionId: string) =>
  client.post<BookmarkResponseType>('/api/v4/collection_videos/bulk_create', {
    bookmarkCollectionId,
    obeClassIds,
  })

export const bulkDeleteClassesFromCollection = (
  obeClassIds: string[],
  bookmarkCollectionId: string,
) =>
  client.post<BookmarkResponseType>('/api/v4/collection_videos/bulk_destroy', {
    bookmarkCollectionId,
    obeClassIds,
  })

export const bulkMoveClassesToCollection = (
  obeClassIds: string[],
  oldBookmarkCollectionId: string,
  newBookmarkCollectionId: string,
) =>
  client.post<BookmarkResponseType>('/api/v4/collection_videos/bulk_update', {
    newBookmarkCollectionId,
    obeClassIds,
    oldBookmarkCollectionId,
  })

export const deleteVideoFromCollection = (obeClassId: string, bookmarkCollectionId: string) =>
  client.delete<BookmarkResponseType>('/api/v4/collection_videos', {
    bookmarkCollectionId,
    obeClassId,
  })

export const createBookmarkCollection = (
  name: string,
  thumbnailImage: string,
  privacySetting = 'public',
) =>
  client.post<Omit<BookmarkCollectionType, 'obeClasses'>>('/api/v3/bookmarks', {
    name,
    thumbnailImage,
    privacySetting,
  })

export const deleteBookmarkCollection = (id: string) =>
  client.delete<Omit<BookmarkCollectionType, 'obeClasses'>[]>(`/api/v3/bookmarks/${id}`)

export const updateBookmarkCollection = (
  id: string,
  name: string,
  privacySetting?: string,
  thumbnailImage?: string,
) =>
  client.put<Omit<BookmarkCollectionType, 'obeClasses'>>(`/api/v3/bookmarks/${id}`, {
    name,
    privacySetting,
    thumbnailImage,
  })

export const getBookmarkCollectionById = (
  id: string,
  includeFollowers = false,
  page = 1,
  perPage = 24,
) =>
  client.get<BookmarkCollectionType>(`/api/v3/bookmarks/${id}`, {
    params: {
      include_followers: includeFollowers,
      include_updates: true,
      page,
      per_page: perPage,
    },
  })

export const getObeClassesInBookmarkCollection = (id: string, page: number, perPage: number) =>
  client.get<Omit<BookmarkCollectionType, 'id'>>(`/api/v3/bookmarks/${id}/obe_classes`, {
    params: {
      page,
      per_page: perPage,
    },
  })

export const followBookmarkCollection = (bookmarkCollectionId: string) =>
  client.post('/api/v3/collection_follows', { collection_id: bookmarkCollectionId })

export const unfollowBookmarkCollection = (id: string) =>
  client.delete(`/api/v3/collection_follows/${id}`)

export const getBookmarkCollections = async (userId?: string) => {
  let response
  let bookmarkCollections

  if (api.sessionState) {
    try {
      response = await client.get<Omit<BookmarkCollectionType, 'obeClasses'>[]>(
        '/api/v3/bookmarks',
        {
          params: {
            ...(userId ? { for_member: userId } : {}),
            include_all_collection: false,
            include_followers: false,
            include_obe_classes_first_page: false,
            include_updates: userId ? false : true,
          },
        },
      )
    } catch (error) {
      return [] as Omit<BookmarkCollectionType, 'obeClasses'>[]
    }

    if (response?.data) {
      const { data } = response
      bookmarkCollections = data
    }

    return bookmarkCollections
  }
}
