import useSwr, { useSWRConfig } from 'swr'

import useUser from 'src/hooks/useUser'
import { getSavedObeClasses } from 'src/models/savedObeClass'

export { clearCache, setSavedClasses } from './setters'
export const getSavedObeClassesUserKey = (userId?: string) =>
  `/api/v3/saved_obe_classes/${userId ? userId : `me/${obe.storage_keys.swr_me_key}`}`

export const useSavedClasses = (userId?: string) => {
  const { isMember } = useUser()
  const USER_KEY = getSavedObeClassesUserKey(userId)
  const { cache } = useSWRConfig()

  if (!isMember && cache.get(USER_KEY)?.data) cache.delete(USER_KEY)

  const { data, isLoading, ...rest } = useSwr(USER_KEY, () => getSavedObeClasses(userId), {
    revalidateOnMount: !cache.get(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  return {
    isLoadingInitialState: isLoading,
    favoriteVideos: data?.favoriteVideos ?? [],
    savedVideos: data?.savedVideos ?? [],
    ...rest,
  }
}

export default useSavedClasses
